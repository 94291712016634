<template>
  <div>
    <v-card
      class="ml-3 mt-3"
      max-width="600"
      outlined
      v-if="selectedWorkerId > 0"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">
            Worker
            <span class="float-right">
              <v-icon
                class="action-icon"
                @click="$store.commit('selectWorker', 0)"
              >
                mdi-close-box
              </v-icon>
            </span>
          </div>
          <v-list-item-title class="text-h5 mb-1">
            {{ selectedWorker.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <div v-if="selectedWorker.unavailableDates">
        <p class="pl-4 mb-0">Current unavailable dates:</p>
        <v-list-item
          style="min-height: 5px;"
          v-for="(unavailable, index) in selectedWorker.unavailableDates"
          :key="index">
          <v-list-item-content class="pb-2 pt-2">
            <v-list-item-subtitle>
              {{ unavailable.start | prettyDateTime }} to {{ unavailable.end | prettyDateTime }}
              &nbsp;
              <v-icon
                class="action-icon"
                small
                color="orange darken-2"
                @click="editHoliday = unavailable"
              >
                mdi-pencil
              </v-icon>
              &nbsp;
              <v-icon
                class="action-icon"
                small
                color="red darken-1"
                style="position:relative; top: -2px;"
                @click="removeHoliday(unavailable.id)"
              >
                mdi-delete
              </v-icon>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>

      <v-card-actions>
        <v-btn
          outlined
          text
          @click="addHoliday = true"
        >
          Add holiday
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      class="ml-3 mt-3"
      max-width="600"
      outlined
      v-else
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1">
            <v-icon color="orange lighten-2">
              mdi-arrow-left-thick
            </v-icon>
            Click on a worker to select
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <v-sheet
      class="pt-3 pl-3 pr-3"
      width="100%"
    >
      <v-calendar
        type="month"
        v-model="month"
        :events="holidays"
        ref="calendar"
      >
      </v-calendar>
      <v-toolbar
        flat
      >
        <v-btn
          outlined
          class="mr-4"
          color="grey darken-2"
          @click="setToday"
        >
          Today
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="prev"
        >
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="next"
        >
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
      </v-toolbar>
    </v-sheet>

    <set-holiday
      v-if="addHoliday"
      @closeSetHoliday="addHoliday = false"
    />

    <edit-holiday
      v-if="editHoliday"
      @closeEditHoliday="editHoliday = false"
      :holidayRecord="editHoliday"
    />
  </div>
</template>

<script>
import SetHoliday from '@/components/Workers/SetHoliday.vue';
import EditHoliday from '@/components/Workers/EditHoliday.vue';
import axios from '../axios';

export default {
  name: 'Holidays',
  components: {
    SetHoliday,
    EditHoliday,
  },
  computed: {
    selectedWorkerId() {
      return this.$store.state.selectedWorkerId;
    },
    selectedWorker() {
      return this.$store.getters.selectedWorker;
    },
    token() {
      return this.$store.state.token;
    },
    workers() {
      return this.$store.state.workers;
    },
  },
  data() {
    return {
      addHoliday: false,
      editHoliday: false,
      holidays: [],
      month: new Date(),
    };
  },
  watch: {
    workers() {
      this.updateHolidayList();
    },
  },
  methods: {
    setToday() {
      this.month = new Date();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    updateHolidayList() {
      const holidays = [];
      for (let i = 0; this.workers.length > i; i += 1) {
        if (this.workers[i].unavailableDates) {
          for (let x = 0; this.workers[i].unavailableDates.length > x; x += 1) {
            holidays.push({
              name: `${this.workers[i].name}: ${this.workers[i].unavailableDates[x].description}`,
              start: new Date(this.workers[i].unavailableDates[x].start),
              end: new Date(this.workers[i].unavailableDates[x].end),
              id: this.workers[i].unavailableDates[x].id,
              color: 'orange darken-2',
            });
          }
        }
      }
      this.holidays = holidays;
    },
    removeHoliday(id) {
      axios.post(`/workerUnavailableDates/remove.json?token=${this.token}`, id)
        .then(() => {
          this.$store.dispatch('refreshWorkers');
        });
    },
  },
  mounted() {
    this.updateHolidayList();
    this.$refs.calendar.checkChange();
  },
};
</script>
